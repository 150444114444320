/*******************************
         Site Overrides
*******************************/
.ui.button.option {
  background-color: transparent;
  font-size: 1.143rem;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 8px 15px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 13px;
  height: 46px;
  text-align: left;
  font-family: 'ProximaNovaRg', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  text-transform: none;

  & > * {
    pointer-events: none;
  }

  span,
  svg {
    z-index: 100;
  }

  &.loading.loading.loading.loading.loading.loading {
    color: #fff !important;

    &::before,
    &::after {
      left: auto;
      right: 10px;
    }

    svg {
      opacity: 0;
    }
  }

  &.disabled {
    color: rgba(255, 255, 255, .3);

    svg {
      opacity: .1;
    }
  }

  .animation-fill-white,
  .animation-fill-other {
    position: absolute;
    border-radius: 6px;
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .animation-fill-white {
    transform: translate3d(-100%, 0, 0);
    background-color: white;
    border: 1px solid white;

    transition: transform 300ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .animation-fill-other {
    transform: translate3d(100%, 0, 0);

    transition: transform 300ms 300ms cubic-bezier(0.16, 0.73, 0.58, 0.62);
  }

  &.correct,
  &.wrong,
  &.no-answer {
    .animation-fill-white,
    .animation-fill-other {
      transform: translate3d(0, 0, 0);
    }
  }

  &.correct .animation-fill-other {
    background-color: #55B261;
    border: 1px solid #16bd88;
  }

  &.wrong .animation-fill-other {
    background-color: #E60F64;
    border: none;
  }

  &.no-answer .animation-fill-other {
    background-color: #CED2D5;
    border: none;
  }

  svg {
    transform: rotate(180deg) translate(13px, 50%);
    position: absolute;
    right: 0;
    top: 50%;

    path {
      fill: #fff;
    }
  }
}