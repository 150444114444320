/*******************************
         Site Overrides
*******************************/
.ui.modal {
  max-width: 320px;
  padding-bottom: 1.5rem !important;
}

.ui.modal > .header {
  padding: 30px 15px 0 !important;
  border: 0 !important;
  text-align: center;

  h1 {
    font-size: 1.286rem;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.ui.modal > .content {
  padding: 0 15px !important;

  p {
    color: #2C2B39;
  }
}

.ui.modal > .content > .description {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

.ui.modal > .actions {
  background: #fff !important;
  border: 0 !important;
}
.ui.modal .actions > .button {
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}